import {
  Button,
  Card,
  Nav,
  Navbar,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useMediaQuery } from "react-responsive";

function App() {
  const navItem = (text: any, variant?: any) => (
    <code className="normal-font-color">
      <Button variant={variant ?? "light"}>{text}</Button>
    </code>
  );

  let s = {
    cardStyle: { marginTop: "1em", marginBottom: "1em" },
    cardEndStyle: { paddingBottom: "0px", marginBottom: "0px" },
    navTheme: "primary",
    cardClassName: "bg-light",
  };
  const isSmallScreen = useMediaQuery({ maxWidth: 991 });
  function renderCardHeader(content: any) {
    return (
      <h4 style={{ textAlign: "center", marginBottom: "0px" }}>{content}</h4>
    );
  }
  function renderProject(projUrl: any, projName: any, projDescription: any) {
    return (
      <>
        <tr
          style={{
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "lightgray",
          }}
        >
          {isSmallScreen ? (
            <td style={{ textAlign: "center" }}>
              <Button href={projUrl}>{projName}</Button>
            </td>
          ) : (
            <>
              <td style={{ textAlign: "center" }}>
                <Button href={projUrl}>{projName}</Button>
              </td>
              <td className="normal-font-color">{projDescription}</td>
            </>
          )}
        </tr>
        {isSmallScreen ? (
          <tr>
            <td className="normal-font-color">{projDescription}</td>
          </tr>
        ) : null}
      </>
    );
  }
  return (
    <>
      <Navbar
        variant="dark"
        bg={s.navTheme}
        expand="lg"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <Navbar.Brand href="/">
          {navItem("Dallin Jackson | Code", s.navTheme)}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <span style={{ width: "100%" }} />
          <Nav>
            {/* <Nav.Link onClick={() => handleShow("Tutorials: Coming Soon!")}>
              {navItem("Tutorials")}
            </Nav.Link> */}
            {/* <Nav.Link onClick={() => handleShow("Blog: Coming Soon!")}>
              {navItem("Blog")}
            </Nav.Link> */}
            <Nav.Link href="https://www.linkedin.com/in/dallinjackson">
              {navItem("LinkedIn")}
            </Nav.Link>
            <Nav.Link href="mailto:dallin@dallinjackson.com">
              {navItem("Email")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 10 }}>
            <div style={{ textAlign: "center", margin: "1em" }}>
              <img
                width="200px"
                src="https://gravatar.com/avatar/7083f066037d2de331caee025196d8be?size=600"
                style={{ borderRadius: "100px" }}
              />
            </div>
            <h1 style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
              Portfolio
            </h1>
            <Card style={s.cardStyle} className={s.cardClassName}>
              <Card.Header>{renderCardHeader(<>Speed Reader</>)}</Card.Header>
              <Table borderless style={s.cardEndStyle}>
                <tbody>
                  {renderProject(
                    "https://speedreader.dallinjackson.com",
                    "Website",
                    <>
                      Paste some text into the app. Set your playback speed via
                      the top-level menu. Read fast.
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
            <Card style={s.cardStyle} className={s.cardClassName}>
              <Card.Header>
                {renderCardHeader(
                  <>
                    Client: <em>History That Doesn't Suck!</em> (<em>HTDS</em>)
                  </>
                )}
              </Card.Header>
              <Card.Body>
                <em>HTDS</em> is an educational podcast with over 30,000 monthly
                listeners.
              </Card.Body>
              <Table borderless style={s.cardEndStyle}>
                <tbody>
                  {renderProject(
                    "https://historythatdoesntsuck.com",
                    "Website",
                    <>
                      The official website for <em>HTDS</em>. Built with
                      ReactJS; hosted via Google Firebase. I'm currently working
                      with my SDE intern,{" "}
                      <a href="https://mavlabsfpv.com/">Alex Ringmeier</a>, to
                      flesh out the 1,100-entry bibliography section of the
                      site.
                    </>
                  )}
                  {renderProject(
                    "https://play.google.com/store/apps/details?id=com.historythatdoesntsuck.history_that_doesn_t_suck",
                    "Android App",
                    <>
                      <p style={{ textAlign: "center", display: "flex" }}>
                        <span style={{ flexGrow: 2 }} />
                        <img
                          // height="300px"
                          style={{ maxHeight: "300px", maxWidth: "45%" }}
                          src="/HTDS-Android-SC2.jpg"
                          alt="Android screenshot 2"
                        />
                        <span style={{ flexGrow: 1 }} />
                        <img
                          // height="300px"
                          style={{ maxHeight: "300px", maxWidth: "45%" }}
                          alt="Android screenshot 1"
                          src="/HTDS-Android-SC1.jpg"
                        />
                        <span style={{ flexGrow: 2 }} />
                      </p>
                      A proof-of-concept / MVP mobile application that I
                      developed using{" "}
                      <a href="https://dotnet.microsoft.com/apps/xamarin/xamarin-forms">
                        Xamarin.Forms
                      </a>
                      . This app allows users to:
                      <ul style={{ margin: "5px" }}>
                        <li>
                          Navigate through the full selection of <em>HTDS</em>{" "}
                          episodes.
                        </li>
                        <li>
                          Play, pause, and pan through individual episodes
                          (Xamarin.Forms doesn't have a ready-made solution for
                          this, so I implemented this myself).
                        </li>
                      </ul>
                      This isn't much different from what you might expect from
                      a typical streaming app like Spotify or iTunes. The real
                      value that could come from an <em>HTDS</em> app would be
                      in Patreon integration (e.g. customizing content according
                      to a user's patron-status)&mdash;so that's where I'm
                      headed next.
                    </>
                  )}
                </tbody>
              </Table>
            </Card>
            <Card style={s.cardStyle} className={s.cardClassName}>
              <Card.Header>{renderCardHeader(<>About Me</>)}</Card.Header>
              <Card.Body style={s.cardEndStyle}>
                <p>
                  I was introduced to computer science as an undergraduate
                  student at U.C. Berkeley, where I completed a double-major in
                  Computer Science and Physics. I finished my bachelor's degree
                  in 2017 and worked as a SWE for Washington River Protection
                  Solutions (<a href="https://wrpstoc.com/">WRPS</a>) in
                  Washington state.
                </p>
                <p>
                  While at WRPS, I worked on a{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Windows Presentation Foundation</Tooltip>}
                  >
                    <a
                      href="https://docs.microsoft.com/en-us/visualstudio/designers/getting-started-with-wpf?view=vs-2019"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WPF
                    </a>
                  </OverlayTrigger>{" "}
                  application for the Department of Energy. I spent most of my
                  time with C# and .NET, building tools for chemical engineers.
                  Customers used the tools I developed to model nuclear waste
                  treatment activities at the{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        "...large U.S. nuclear site established during World War
                        II for the production of plutonium, some of which was
                        used in the first atomic bomb... After ceasing
                        operations in 1990, the Hanford Site became the biggest
                        environmental cleanup job in U.S. history."
                      </Tooltip>
                    }
                  >
                    <a
                      href="https://www.britannica.com/topic/Hanford-Engineer-Works"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hanford site
                    </a>
                  </OverlayTrigger>
                  .
                </p>
                <p>
                  In early 2021 I joined Microsoft Azure's Commerical
                  Experiences team. My team at Microsoft owned Azure's public
                  and internal subscription management APIs. I built APIs at
                  Microsoft for a little over a year before joining Google in
                  June of 2022.
                </p>
                <p>
                  I currently work on Google's GCS metadata site reliability
                  engineering team in Seattle, WA.
                </p>
              </Card.Body>
            </Card>
            {/* <Card style={s.cardStyle} className="bg-dark">
              <Card.Header>Columbia Judo Dojo (CJD)</Card.Header>
              <Table borderless variant="dark">
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <Button>Website</Button>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
